.about-me {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
  color: rgb(226 232 240);
}

.left-section,
.right-section {
  width: 100%;
  padding: 20px;
}

.left-section {
  display: flex;
  flex-direction: column;
}

.right-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.name {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 0;
}

.title {
  font-size: 1rem;
  margin-bottom: 0;
}

.subtitle {
  color: rgb(148 163 184);
  margin-bottom: 20px;
}

.avatar-container {
  position: relative;
  display: flex;
  justify-content: center;
}

.profile-picture img {
  --s: 150px;
  --b: 4px;
  --c: #5272ff;
  --f: 1;

  --_g: 50% / calc(100% / var(--f)) 100% no-repeat content-box;
  --_o: calc((1 / var(--f) - 1) * var(--s) / 2.2 - var(--b));

  width: var(--s);
  aspect-ratio: 1;
  padding-top: calc(var(--s) / 5);
  cursor: pointer;
  border-radius: 0 0 999px 999px;
  outline: var(--b) solid var(--c);
  outline-offset: var(--_o);
  background: radial-gradient(
      circle closest-side,
      #adaeb2 calc(99% - var(--b)),
      var(--c) calc(100% - var(--b)) 99%,
      #0000
    )
    var(--_g);
  mask: linear-gradient(#000 0 0) no-repeat 50% calc(-1.3 * var(--_o)) /
      calc(100% / var(--f) - 2.2 * var(--b)) 50%,
    radial-gradient(circle closest-side, #000 99%, #0000) var(--_g);
  transform: scale(var(--f));
  transition: 0.5s;
}
.profile-picture img:hover {
  --f: 1.35;
}

.summary {
  margin-top: 15px;
  width: 100%;
}

.summary p {
  color: rgb(148 163 184);
}

.social-links {
  margin-top: 20px;
}

.social-links a {
  margin-right: 15px;
  text-decoration: none;
}
.social-links svg {
  fill: rgb(148 163 184);
  transition: all 0.1s ease-in-out;
  width: 1.5rem;
  height: 1.5rem;
}
.social-links a:hover {
  text-decoration: underline;
}

@media (min-width: 768px) {
  .about-me {
    flex-direction: row;
    justify-content: space-between;
  }

  .avatar-container{
    justify-content: flex-start;
  }

  .left-section,
  .right-section {
    width: 50%;
  }

  .name {
    font-size: 3rem;
  }

  .title {
    font-size: 1.25rem;
  }

  .profile-picture img {
    --s: 200px;
    --b: 5px;
  }
}

.buttons-container {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap; /* Allows buttons to wrap if needed */
  gap: 15px;
  justify-content: center; /* Centers buttons on smaller screens */
}

.resume-button {
  background-color: #5273ff;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  flex: 1; /* Allows the button to grow/shrink */
  min-width: 200px; /* Ensures a minimum width */
  max-width: 300px; /* Prevents overly large buttons */
}

.resume-button .share-icon {
  width: 1rem;
  height: 1rem;
}

.resume-button:hover {
  background-color: #405fcc;
}

.connect-button {
  background: none;
  color: rgb(148 163 184);
  border: 2px solid transparent;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  padding: 10px 20px;
  flex: 1; /* Allows the button to grow/shrink */
  min-width: 200px; /* Ensures a minimum width */
  max-width: 300px; /* Prevents overly large buttons */
  text-align: center;
}

.connect-button:hover {
  text-decoration: underline;
}

@media (min-width: 768px) {
  .buttons-container {
    justify-content: flex-start; /* Aligns buttons to the left on larger screens */
  }

  .resume-button, .connect-button {
    flex: none; /* Prevents buttons from stretching unnecessarily */
    width: auto; /* Allows natural size on larger screens */
  }
}
