.sidebar-content .skill-tag {
  background: #132866;
  padding: 6px 13px;
  border-radius: 4px;
  text-transform: capitalize;
  font-size: 11px;
  margin-right: 6px;
  color: #e1e1e1;
  font-weight: 700;
  margin-bottom: 0.25rem;
}

.skill-tag img {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.sidebar-modal-wrapper {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 1000;
}

.sidebar-modal-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.sidebar-container {
  background-color: #020a13;
  width: 100%;
  height: 100%;
  position: fixed;
  animation-name: fadeInLeft;
  animation-duration: 0.5s;
  animation-fill-mode: both;
  top: 0;
  z-index: 999999;
  transition: all 0.3s linear;
  will-change: opacity, transform;
  overflow-y: auto;
}

.sidebar-container.right {
  right: 0;
}

.sidebar-container.left {
  left: 0;
}
@media only screen and (min-width: 768px) {
  .sidebar-container {
    width: 34em;
  }
}
@keyframes fadeInLeft {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes fadeInRight {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

.sidebar-container.right {
  animation-name: fadeInLeft;
}

.sidebar-container.left {
  animation-name: fadeInRight;
}

.sidebar {
  position: relative;
  padding: 2rem 1rem 4rem;
  overflow-x: hidden;
  max-height: 80%;
}

.sidebar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  padding-bottom: 0.8rem;
  border-bottom: 1px solid #14202b;
}

.sidebar-header a {
  color: #3f566e;
  font-size: 0.875em;
  font-weight: 900;
  text-decoration: none;
}

.sidebar-header a:hover {
  text-decoration: underline;
}

.close-button {
  background: none;
  border: none;
  cursor: pointer;
}

.sidebar-content {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.sidebar-content h3 {
  font-size: 1.5em;
  color: #e1e1e1;
  font-weight: 800;
}

.sidebar-content p {
  color: #c6c6c6;
  font-size: 1em;
}

.sidebar-content h4 {
  font-size: 1.2em;
  color: #e1e1e1;
  margin-top: 2rem;
  margin-bottom: 0.8rem;
}

.project-details-image {
  width: 100%;
  height: auto;
  max-height: 300px;
  border-radius: 11px;
  vertical-align: middle;
  border-style: none;
}

.project-links a {
  display: block;
  margin: 5px 0;
  overflow-wrap: break-word;
}

.open-project-button {
  background: #3f566e;
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  padding: 17px;
  color: #fff;
  font-weight: 600;
  text-align: center;
  font-size: 1em;
  border: none;
}

.open-project-button:hover {
  text-decoration: underline;
  cursor: pointer;
}

.project-links svg,
.open-project-button svg {
  margin-right: 8px;
  width: 16px;
  vertical-align: bottom;
  fill: #fff;
}

.project-links a {
  font-weight: 600;
  color: #3f566e;
  cursor: pointer;
}

.project-links a:hover {
  text-decoration: underline;
}
