.footer {
  color: #ffffff;
  padding: 20px;
}

.footer-sections {
  display: flex;
  justify-content: space-between;
}

.left-footer-section,
.right-footer-section {
  flex: 1;
  padding: 20px;
}

.left-footer-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.right-footer-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.footer-logo img {
  max-height: 12rem;
}

.footer-caption {
  max-width: 320px;
  font-size: 1.6rem;
  text-align: center;
  opacity: 0.8;
}

.footer-icons {
  margin-top: 20px;
}

.footer-icons a {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  border-radius: 100%;
  margin: 0 0.8rem;
  cursor: pointer;
  height: 3rem;
  width: 3rem;
  border: 2px solid rgba(255, 255, 255, 0.5);
  transition: background 0.3s, transform 0.3s;
}

.footer-icons a:hover {
  border-color: #ffffff;
  background-color: #ffffff;
}

.footer-icons a:hover svg {
  fill: rgb(0, 0, 0);
}

.footer-icons svg {
  fill: #ffffff;
  transition: all 0.1s ease-in-out;
  width: 1.5rem;
  height: 1.5rem;
}

.footer-icons a:hover {
  text-decoration: underline;
}


@media (max-width: 768px) {
  .footer-sections {
    flex-direction: column;
    align-items: center;
  }

  .left-footer-section{
    width: 100%;
    padding: 10px;
  }

  .right-footer-section{
    width: 100%;
    padding: 10px;
    text-align: center;
  }

  .footer-caption {
    font-size: 1.1rem;
  }
  .footer-logo img {
    max-height: 10rem;
  }
  .footer-icons {
    margin-top: 10px;
  }

  .footer-icons a {
    margin: 0 0.5rem;
    height: 2.5rem;
    width: 2.5rem;
  }

  .footer-icons svg {
    width: 1.2rem;
    height: 1.2rem;
  }

  .copyright{
    font-size: 0.8rem;
    margin-top: 1rem;
  }
}
