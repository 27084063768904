.home-page {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.experience-timeline {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.project-tabs {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.category-tabs {
  list-style: none;
  display: flex;
  padding: 0;
  margin-bottom: 20px;
  overflow-x: auto;
  white-space: nowrap;
  width: 100%;
  justify-content: center;
}

.category-tabs li {
  cursor: pointer;
  padding: 10px 20px;
  margin: 5px;
  border-radius: 5px;
  transition: background-color 0.3s;
  display: inline-block;
}

.category-tabs li.active,
.category-tabs li:hover {
  background-color: #5273ff;
  color: white;
}

.projects-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
  gap: 20px;
  width: 100%;
}
.scroll-top-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #333;
  color: white;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.scroll-top-button:hover {
  background-color: #555;
}

@media (max-width: 768px) {
  .home-page {
    padding: 0 10px;
  }

  .category-tabs {
    justify-content: flex-start;
  }

  .category-tabs li {
    padding: 10px;
  }

  .projects-list {
    flex-direction: column;
    gap: 10px;
  }
}

@media (max-width: 480px) {
  .home-page {
    padding: 0 5px;
  }

  .category-tabs li {
    padding: 10px;
  }

  .projects-list {
    flex-direction: column;
    gap: 10px;
  }
}
