.timeline-item {
  display: flex;
  padding: 0.5rem;
  position: relative;
  transition: background 0.3s, transform 0.3s;
  border-radius: 0.5rem;
}

.timeline-item:hover {
  background: rgba(100, 116, 139, 0.1);
  backdrop-filter: blur(5px);
  cursor: pointer;
}

.timeline-content {
  display: grid;
  grid-template-columns: 2fr 8fr;
  gap: 20px;
  width: 100%;
}

.timeline-dates {
  font-size: 14px;
  font-weight: bold;
  color: rgb(100 116 139);
  letter-spacing: 0.025em;
  margin: 0 0.5rem;
}

.timeline-details {
  position: relative;
}

.timeline-details h3 {
  font-size: 16px;
  margin: 0 0 5px 0;
  transition: color 0.3s;
}

.timeline-item:hover .timeline-details h3 {
  color: #38b2ac;
}

.timeline-details .description {
  margin: 10px 0;
  padding: 0;
  list-style-type: none;
  margin-top: 0.5rem;
  color: rgb(148 163 184);
}

.timeline-details .description li {
  font-size: 14px;
}

.timeline-details .skills {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  padding: 0;
}

.timeline-details .skill {
  margin-right: 10px;
  margin-bottom: 5px;
  padding: 0.25rem 0.75rem;
  font-size: 0.75rem;
  color: rgb(94 234 212);
  font-weight: 500;
  background-color: rgba(45, 212, 191, 0.1);
  border-radius: 9999px;
  align-items: center;
}

.timeline-details svg {
  transition: transform cubic-bezier(0.4, 0, 0.2, 1) 0.15s;
  flex-shrink: 0;
  width: 1rem;
  height: 1rem;
  vertical-align: middle;
}

.timeline-item:hover svg {
  transform: translate(2px, -2px);
}

@media (max-width: 768px) {
  .timeline-content {
    grid-template-columns: 1fr;
    gap: 10px;
  }

  .timeline-dates {
    font-size: 12px;
    margin: 0 0.5rem;
  }

  .timeline-details h3 {
    font-size: 14px;
  }

  .timeline-details .description li {
    font-size: 12px;
    margin: 0 0.5rem;
  }

  .timeline-details .skill {
    font-size: 0.65rem;
    padding: 0.2rem 0.6rem;
  }
}

@media (max-width: 480px) {
  .timeline-dates {
    font-size: 11px;
    font-weight: 500;
    margin: 0;
  }

  .timeline-details h3 {
    font-size: 13px;
  }

  .timeline-details .description li {
    font-size: 11px;
  }

  .timeline-details .skill {
    font-size: 0.75rem;
    padding: 0.25rem 0.75rem;
  }
}
