.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background: transparent;
  color: white;
  position: relative;
  z-index: 1000;
}

.navbar-logo img {
  margin-left: 20px;
  max-height: 80px;
}

.navbar-links {
  display: flex;
  align-items: center;
}

.navbar-links a {
  color: white;
  text-decoration: none;
  margin: 0 10px;
}

.navbar-links a:hover {
  text-decoration: underline;
}

.navbar-toggle {
  display: none;
  font-size: 1.5rem;
  cursor: pointer;
  z-index: 1100; /* Added */
}

.navbar-overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.9);
  z-index: 1000;
}

@media (max-width: 768px) {
  .navbar-links {
    display: none;
    align-items: center;
    position: fixed;
    top: 10%;
    left: 0;
    width: 100%;
    height: 80vh;
    flex-direction: column; 
    justify-content: space-evenly;
    z-index: 1100;
  }

  .navbar-links a {
    color: white;
    text-decoration: none;
    margin: 10px 0;
  }

  .navbar-links.active {
    display: flex;
  }

  .navbar-toggle {
    display: block;
  }

  .navbar-logo img {
    max-height: 60px;
    margin-left: 0;
  }

  .navbar-links a {
    margin: 10px 0;
  }

  .navbar-overlay.active {
    display: block;
    overflow: hidden;
  }
}
