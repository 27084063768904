.snackbar {
  visibility: visible;
  min-width: 250px;
  max-width: 80%;
  margin: 0 auto;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 2px;
  padding: 16px;
  position: fixed;
  z-index: 1;
  left: 50%;
  bottom: 30px;
  font-size: 17px;
  transform: translateX(-50%);
  transition: visibility 0.5s linear, bottom 0.5s ease-in-out;
}

@media (max-width: 768px) {
  .snackbar {
    font-size: 15px;
    padding: 14px;
    bottom: 20px;
  }
}

@media (max-width: 480px) {
  .snackbar {
    font-size: 13px;
    padding: 12px;
    bottom: 10px;
  }
}
