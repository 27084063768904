@keyframes navItemIn {
  0% {
    width: 2rem;
    background-color: transparent;
  }
  100% {
    width: 10rem;
    background-color: rgba(1, 1, 1, 0.8);
  }
}

@keyframes navItemOut {
  0% {
    width: 10rem;
    background-color: rgba(1, 1, 1, 0.8);
  }
  100% {
    width: 2rem;
    background-color: transparent;
  }
}

@keyframes textIn {
  0% {
    opacity: 0;
    transform: translateX(-10px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes textOut {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(-10px);
  }
}
.side-navigation-bar {
  position: fixed;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 100;
  background-color: rgba(1, 1, 1, 0.8);
  width: 3rem;
}

.side-navigation-bar ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.side-navigation-bar .nav-item {
  cursor: pointer;
  background-color: transparent;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  height: 2rem;
  width: 3rem;
  animation: navItemOut 0.3s ease-in-out;
  animation-fill-mode: forwards;
  position: relative;
  overflow: hidden;
}

.side-navigation-bar .nav-item:hover {
  animation: navItemIn 0.3s ease-in-out;
  animation-fill-mode: forwards;
}

.side-navigation-bar .nav-item.active::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 5px;
  height: 50%;
  background-color: #ffffff;
  border-radius: 2px;
}

.side-navigation-bar .nav-text {
  display: none;
  margin-left: 2rem;
  white-space: nowrap;
  color: #ffffff;
  opacity: 0;
  transform: translateX(-10px);
  animation: textOut 0.3s ease-in-out;
  animation-fill-mode: forwards;
}

.side-navigation-bar .nav-item:hover .nav-text {
  display: block;
  animation: textIn 0.3s ease-in-out;
  animation-fill-mode: forwards;
}

.side-navigation-bar .nav-icon {
  position: fixed;
  margin-right: 10px;
}

@media (max-width: 480px) {
  .side-navigation-bar {
    display: none;
  }
}