body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  background: #0d0d0d 25%;
  color: #e0e0e0;
  overflow: auto;
}

.custom-cursor {
  position: fixed;
  top: 0;
  left: 0;
  width: 600px;
  height: 600px;
  border-radius: 50%;
  pointer-events: none;
  transform: translate(-50%, -50%);
  transition: transform 0.1s ease-out, width 0.1s ease-out, height 0.1s ease-out;
  z-index: -1;
  background: radial-gradient(circle at center, rgba(255, 255, 255, 0.08) 0%, rgba(255, 255, 255, 0) 70%);
}

a:hover ~ .custom-cursor {
  width: 30px;
  height: 30px;
  background: radial-gradient(circle at center, #fe5d5b 0%, rgba(254, 93, 91, 0.3) 50%, rgba(254, 93, 91, 0) 100%);
}

section {
  padding: 1.5rem;
  margin-left: auto;
  margin-right: auto;
}

h2 {
  color: #5272ff;
}

p {
  color: #ddd;
}

a:hover {
  text-decoration: underline;
}