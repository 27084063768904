.project-card {
  position: relative;
  width: 45%;
  max-width: 600px;
  margin: 15px;
  cursor: pointer;
  border-radius: 10px;
  overflow: hidden;
  transition: transform 0.3s;
}

.project-card:hover {
  transform: scale(1.05);
}

.project-image {
  position: relative;
  width: 100%;
  height: 300px;
  overflow: hidden;
}

.project-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.project-overlay {
  position: absolute;
  bottom: 0;
  width: 100%;
  color: white;
  padding: 10px;
  box-sizing: border-box;
  transition: opacity 0.3s, transform 0.3s;
  opacity: 0;
  inset: 0px;
  background: linear-gradient(rgba(0, 0, 0, 0.1) 10%, rgba(0, 0, 0, 0.78) 80%);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  pointer-events: none;
}

.project-overlay h3 {
  margin: 0.3rem 0;
}

.project-overlay p {
  margin: 0.3rem 0;
}

.project-card:hover .project-overlay {
  opacity: 1;
  transform: translateY(0);
}

.skills-used {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-top: 10px;
}

.skill-tag {
  display: flex;
  align-items: center;
  background: rgb(105, 104, 105);
  border-radius: 50px;
  text-transform: capitalize;
  margin-right: 6px;
  padding: 4px 10px;
  font-size: 11px;
  font-weight: 500;
  color: white;
}

@media (max-width: 768px) {
  .project-card {
    width: 90%;
    margin: 10px auto;
  }

  .project-image {
    height: 200px;
  }

  .project-overlay {
    opacity: 1;
    transform: translateY(0);
    pointer-events: auto;
  }

  .project-overlay p {
    font-size: 12px;
  }

  .skills-used {
    justify-content: center;
  }

  .skill-tag {
    font-size: 10px;
  }
}

@media (max-width: 480px) {
  .project-card {
    width: 100%;
    margin: 10px 0;
  }

  .project-image {
    height: 200px;
  }

  .project-overlay {
    opacity: 1;
    transform: translateY(0);
    pointer-events: auto;
  }
  
  .project-overlay p {
    font-size: 12px;
  }

  .skills-used {
    justify-content: flex-start;
    gap: 3px;
  }

  .skill-tag {
    font-size: 9px;
    padding: 3px 8px;
  }
}
